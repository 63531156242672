/* .FM-cites {
    display: flex;
} */

.FM-cites-top {
    display: flex;
    align-items: center;
}

.FM-cites-top button {
    border-radius: 90px;
    color:  var(--color);
    background: none;
    font-size: 20px;
    padding: 10px 7px;
    cursor: pointer;
    border: none;
}

.FM-cites-top button:active {
    background-color: rgba(0,0,0,0.15);
}

.FM-cites-head {
    background-color: var(--color);
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    display: flex;
    padding: 10px;
    height: 40px;
    width: 100%;
}

.FM-cites-body {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 25px;
}

@keyframes loading {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.FM-cites-body .FM-time {
    animation: loading 0.5s;
    border: 1px solid currentColor;
    color:  var(--color);
    border-radius: 5px;
    background: none;
    cursor: pointer;
    margin: 3px 1.5%;
    width: 30.333%;
    padding: 5px;
}

.FM-cites-body .FM-time.FM-selected {
    background-color:  var(--color);
    color: white;
}

.FM-cites-value {
    text-align: center;
    color:  var(--color);
    cursor: pointer;
}

.FM-cites-value:active {
    opacity: 0.5;
}

.FM-tap {
    padding: 5px 30px;
}

.FM-tap-item {
    background: none;
    border: none;
    cursor: pointer;
    color:  var(--color);
    opacity: 0.5;
}

.FM-tap-item.FM-selected {
    opacity: 1;
    color:  var(--color)
}