.FM-element-group {
    display: flex;
}

.FM-element-group.FM-horz {
    flex-direction: row;
}

.FM-element-group.FM-vert {
    flex-direction: column;
}