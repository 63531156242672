.FM-form-struct {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: 50%;
    --color: dodgerblue;
}

.FM-form-struct .FM-form-page {
    box-sizing: border-box;
    max-width: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 10px 10px 10px 5px rgba(0,0,0,0.1)
}