.FM-element {
    width: 100%;
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    padding: 10px 0px;
}

.FM-element-container {
    display: flex;
    justify-content: stretch;
    flex-direction: column;
}

.FM-element.FM-align-left .FM-element-container {
    flex-direction: row;
    justify-content: flex-start;
}

.FM-element.FM-align-right .FM-element-container {
    flex-direction: row;
    justify-content: flex-end;
}