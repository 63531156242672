.FM-form-page {
    width: 100%;
}

.FM-form-page-title {
    color: var(--color);
    font-size: 1.1em;
    font-family: Arial;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.FM-form-page-subtitle {
    color: var(--color);
    font-size: 1.1em;
    font-family: Arial;
    font-weight: bold;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    text-align: center;
}

.FM-form-page-container {
    padding: 10px 0px;
}

.FM-form-error {
    color: darkred;
}

.FM-div-logo{
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
}

.FM-logo{
    width: 60%;
}